<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="'Создание новой услуги'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div>
      <b-row>
        <b-col>
          <b-form-group label="Название услуги">
            <b-form-input
              v-model="$v.serviceName.$model"
              type="text"
              :state="validateState('serviceName')"
              :placeholder="'Введите название услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Описание услуги">
            <classic-editor
              v-model="$v.serviceDescription.$model"
              :class="{
                'is-invalid': validateState('serviceDescription') === false,
              }"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :label="`Подготовка к анализу / ${countSelectedPreparation}`"
          >
            <span v-if="errors.preparations" class="error-text">
              {{ errors.preparations }}
            </span>
            <base-dropdown
              v-if="preparation && preparation?.length"
              header="Выбрать"
              class="crm-form-step"
              :open="steps['1'].open"
              @toggleShowStatus="toggleStepShowStatus(1)"
            >
              <template v-if="steps['1'].open">
                <div class="grid gap-y-2 p-3 bg-v-ui-background-monochrome-5">
                  <b-form-checkbox
                    v-for="(item, index) in preparation"
                    :key="item.id"
                    v-model="preparationList[index].status"
                    name="checkbox-1"
                    switch
                  >
                    <preparation-analyses-card
                      :icon="item.logoUrl"
                      :text="item.text"
                      class="cursor-pointer"
                    />
                  </b-form-checkbox>
                </div>
              </template>
            </base-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-form-group
            label="Код услуги"
          >
            <b-form-input
              v-model="$v.serviceCode.$model"
              type="text"
              :state="validateState('serviceCode')"
              :placeholder="'Введите код услуги'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> -->
      <!-- <b-row>
        <b-col>
          <b-form-group
            label="Стоимость услуги"
          >
            <b-form-input
              v-model="serviceCost"
              type="text"
              :placeholder="'Введите стоимость услуги'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select v-model="isActive" :options="optionsActivate" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox v-model="isCheckup" size="sm" @change="onCheckUp">
            <span class="text-secondary font-weight-bold"> Чекап </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <transition name="fade">
        <b-row v-if="isCheckup">
          <b-col>
            <b-form-group label="Дочерние услуги">
              <multiselect
                v-model="$v.selected.$model"
                :multiple="true"
                placeholder="Выберите дочернюю услугу"
                :close-on-select="false"
                label="name"
                track-by="id"
                :searchable="true"
                :options="serviceList"
                @search-change="fetchServices"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </transition>
      <b-row v-if="$v.selected.$error" class="mb-5">
        <b-col>
          <div class="validation-errors">
            <span class="validation-error-text">
              Данное поле обязательно*
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox
            v-model="$v.isRecommended.$model"
            size="sm"
          >
            <span class="text-secondary font-weight-bold"> Советуем сдать </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <transition name="fade">
        <b-row v-if="isRecommended">
          <b-col>
            <b-form-group label="Приоритет услуги (приоритет выше у большего значения)">
              <b-form-input
                v-model.trim.lazy="$v.recommendationPriority.$model"
                type="number"
                :state="validateState('recommendationPriority')"
                min="1"
                :placeholder="'Введите приоритет услуги'"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ !$v.recommendationPriority.required ? 'Данное поле обязательно*' : 'Значение должно быть больше 1' }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </transition>
      <b-row>
        <b-col>
          <b-form-group label="Категории">
            <multiselect
              v-model="serviceTags"
              :multiple="true"
              placeholder="Выберите категорию"
              :close-on-select="false"
              label="name"
              track-by="id"
              :options="tags"
              @search-change="fetchTags"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Синонимы">
            <div class="flex gap-x-4 items-end">
              <b-form-input
                v-model="synonym"
                type="text"
                :placeholder="'Введите синонимы к услуге'"
                trim
                @keydown.enter="onAddSynonym()"
              />
              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                class="shrink-0"
                @click="onAddSynonym()"
              >
                Добавить
              </b-button>
            </div>
            <ul
              v-if="synonyms && synonyms?.length"
              class="mt-3 flex gap-2 flex-wrap"
            >
              <li
                v-for="(synonym, index) in synonyms"
                :key="synonym"
                class="
                  flex
                  justify-start
                  items-center
                  min-h-6
                  p-2
                  note-12-reg-400
                  gap-2
                  rounded
                  text-v-ui-text-service-info-monochrome
                  bg-v-ui-light-service-info-monochrome
                "
              >
                <span>{{ synonym }}</span>
                <button
                  style="color: red"
                  class="text-sm"
                  @click="onRemoveSynonym(index)"
                >
                  X
                </button>
              </li>
            </ul>
            <div
              v-if="synonyms && synonyms?.length"
              class="p-4 bg-v-ui-background-monochrome-5"
            >
              <synonyms :synonyms="synonyms" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickCreateService"
        >
          Создать
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { api } from '@/helpers/api';
import debounce from 'lodash.debounce';
import Loading from '@/components/Loading';
import PreparationAnalysesCard from '@/components/Laboratories/PreparationAnalysesCard';
import { BaseDropdown } from '@/components/base';
import Synonyms from '@/components/Laboratories/Modals/Services/Synonyms.vue';
import ClassicEditor from '@/components/common/CKEditor/ClassicEditorComponent.vue';

const FETCH_DELAY = 700;

export default {
  name: 'AddServiceModal',
  components: {
    ClassicEditor,
    Loading,
    Synonyms,
    BaseDropdown,
    PreparationAnalysesCard,
  },
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    preparation: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      rows: [],
      selected: [],
      serviceName: '',
      // serviceCode: '',
      serviceCost: null,
      serviceDescription: '',
      serviceTags: [],
      tags: [],
      isActive: null,
      isCheckup: false,
      // legalPartnerId: '',
      // optionsLegalPartner: [],
      optionsActivate: [
        { value: null, text: 'Выберите состояние' },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      serviceList: [],
      synonym: '',
      synonyms: null,
      preparationList: [],
      steps: {
        1: {
          open: false,
          disabled: false,
        },
      },
      errors: {
        preparations: null,
      },
      isRecommended: false,
      recommendationPriority: null,
    };
  },
  validations() {
    return {
      serviceName: { required },
      // serviceCode: { required },
      serviceDescription: { required },
      selected: {
        required: this.isCheckup ? required : () => true,
      },
      recommendationPriority: {
        required: requiredIf(() => this.isRecommended),
        minValue: minValue(1),
      },
      isRecommended: {},
    };
  },
  computed: {
    countSelectedPreparation() {
      if (this.preparationList?.length) {
        const listSelectedItems = this.preparationList.filter(
          (item) => item.status,
        );
        return listSelectedItems.length;
      }
      return 0;
    },
    selectedPreparation() {
      if (this.preparationList?.length) {
        const listSelectedItems = this.preparationList.filter(
          (item) => item.status,
        );
        return listSelectedItems.map((item) => item.id);
      }
      return [];
    },
  },
  watch: {
    isRecommended(newValue) {
      if (!newValue) {
        this.recommendationPriority = null;
      }
    },
  },
  async created() {
    await this.fetchServices();
    await this.fetchTags();
    this.setPreparationList();
  },
  methods: {
    setPreparationList() {
      if (this.preparation?.length) {
        const newPreparation = this.preparation.map((item) => ({
          id: item.id,
          status: false,
        }));
        this.preparationList = [...newPreparation];
      }
    },
    onCheckUp() {
      this.selected = [];
    },
    async onClose() {
      this.$emit('input', false);
    },
    fetchTags: debounce(async function fetchTags(query) {
      const {
        data: { data },
      } = await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, {
        isActive: true,
        Name: query,
      });

      this.tags = data;
    }, FETCH_DELAY),
    fetchServices: debounce(async function fetchServices(query) {
      const params = new URLSearchParams();

      params.append('isMerged', true);
      if (query) params.append('Name', query);

      const {
        data: { data },
      } = await api.get('v3/laboratory/service/list', {
        params,
      });
      this.serviceList = data;
    }, FETCH_DELAY),
    async onClickCreateService() {
      if (!this.onCheckValidation()) return;
      const tagsIds = this.serviceTags.map((item) => item.id);
      const childIds = this.selected.map((item) => item.id);
      const params = {
        name: this.serviceName,
        // code: this.serviceCode,
        cost: +this.serviceCost,
        description: this.serviceDescription,
        preparations: this.selectedPreparation,
        childIds,
        isActive: this.isActive ?? false,
        tags: tagsIds,
        synonyms: this.synonyms,
        isRecommended: this.isRecommended,
        recommendationPriority: this.recommendationPriority,
      };
      await this.$store.dispatch(this.$types.LABORATORY_SERVICE_CREATE, params);
      await this.$store.dispatch(
        this.$types.GET_LABORATORY_SERVICES,
        this.params,
      );
      this.onClose();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.serviceName.$touch();
      // this.$v.serviceCode.$touch();
      this.$v.serviceDescription.$touch();
      this.$v.selected.$touch();
      this.$v.recommendationPriority.$touch();
      this.errors.preparations = null;
      if (!this.selectedPreparation?.length) {
        this.errors.preparations = 'Данное поле обязательно*';
        return false;
      }
      return !this.$v.$error;
    },
    formatInn(e) {
      return String(e).substring(0, 12);
    },
    formatKpp(e) {
      return String(e).substring(0, 9);
    },
    onAddSynonym() {
      if (!this.synonym.length) return;

      if (!this.synonyms) {
        this.synonyms = [];
      }

      this.synonyms.push(this.synonym);
      this.synonym = '';
    },
    onRemoveSynonym(index) {
      this.synonyms.splice(index, 1);
      if (!this.synonyms.length) {
        this.synonyms = null;
      }
    },
    toggleStepShowStatus(index) {
      this.steps[index].open = !this.steps[index].open;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.custom-switch .custom-control-label {
  width: 100%;
}

::v-deep.base-dropdown-wrapper {
  @apply mb-0;
}

.crm-table {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #f3f3f3;
      color: #6e88f3;
    }

    & + & {
      border-top: 1px solid #e0e0e0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #e0e0e0;
    }
  }
}
.error-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.is-invalid {
  --ck-color-base-border: #dc3545;
  --ck-focus-ring: 1px solid #dc3545;
}
</style>
